/**
 * @description element-plus provider
 */

import type { App } from 'vue'
import ElementPlus from 'element-plus'
// // Element Plus 额外提供了一系列类名，用于在某些条件下隐藏元素。
import 'element-plus/theme-chalk/display.css'
// 官方指定方式会导致样式重复加载，暂时屏蔽
// import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/dark/css-vars.css'

function install(app: App) {
  app.use(ElementPlus)
}

export default { install }
