import { setupLayouts } from 'virtual:meta-layouts'
import generatedRoutes from 'virtual:generated-pages'
import type { RouteRecordRaw } from 'vue-router'
// import MultilevelMenuExample from './modules/multilevel.menu.example'
// import BreadcrumbExample from './modules/breadcrumb.example'
import type { Route } from '@/types/global'
import useSettingsStore from '@/store/modules/settings'

function Layout() {
  return import('@/layouts/index.vue')
}

// 固定路由（默认路由）
const constantRoutes: RouteRecordRaw[] = [
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: () => import('@/views/login.vue'),
  //   meta: {
  //     title: '登录',
  //   },
  // },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/loginIndex.vue'),
    meta: {
      title: '登录',
      hidden: true,
    },
  },
  {
    path: '/',
    component: Layout,
    meta: {
      // title: () => useSettingsStore().settings.home.title,
      title: '首页',
      icon: 'icon-home-solid',
      breadcrumb: false,
      hidden: false
    },
    redirect: '/data-middle-office',
    children: [
      {
        path: 'data-middle-office',
        component: () => import('@/views/dashboard/index.vue'),
        name: 'DataMiddleOffice',
        meta: {
          title: '首页',
          icon: '',
          affix: true,
          hidden: false
        }
      },
      {
        path: 'demo',
        name: 'Demo',
        // redirect: '/demo/demo-navigator-location',
        component: () => import('@/views/demo/index.vue'),
        meta: {
          title: 'demo',
          hidden: true
        },
        children: [
          {
            path: 'demo-navigator-location',
            component: () => import('@/views/demo/components/navigatorLocationComp.vue'),
            name: 'NavigatorLocationComp',
            meta: { title: 'navigator-location', icon: 'icon-home-solid', affix: true }
          },
          {
            path: 'g2n-comp',
            component: () => import('@/views/demo/components/g2nComp.vue'),
            name: 'G2nComp',
            meta: { title: 'g2n-comp', icon: 'icon-home-solid', affix: true }
          },
          {
            path: 'config-budget-page-comp',
            component: () => import('@/views/demo/components/configBudgetPageComp.vue'),
            name: 'ConfigBudgetPageComp',
            meta: { title: 'g2n-comp', icon: 'icon-home-solid', affix: true }
          },
          /* {
            path: 'promotion-voucher-rule',
            component: () => import('@/views/demo/promotionVoucher/promotionVoucherRule/index.vue'),
            name: 'PromotionVoucherRule',
            meta: { title: 'promotion-voucher-rule', icon: 'icon-home-solid', affix: true }
          }, */
          {
            path: 'create-promotion-voucher-rule',
            component: () => import('@/views/demo/promotionVoucher/promotionVoucherRule/components/createPromotionVoucherRule.vue'),
            name: 'CreatePromotionVoucherRule',
            meta: { title: 'create-promotion-voucher-rule', icon: 'icon-home-solid', affix: true }
          },
          /* {
            path: 'test-standard-table',
            component: () => import('@/views/demo/components/testTableComp'),
            name: 'tableComop',
            meta: { title: 'test-standard-table', icon: 'icon-home-solid', affix: true }
          },
          {
            path: 'upload-comp',
            component: () => import('@/views/demo/components/uploadComp'),
            name: 'uploadComop',
            meta: { title: 'upload-comp', icon: 'icon-home-solid', affix: true }
          } */
        ]
      }
    ]
  },
  {
    id: '5',
    parentId: '',
    // component: 'loginedLayout',
    component: () => import('@/layouts/personalInfoLayout.vue'),
    path: '/personal-info',
    enabled: '1',
    redirect: '/personal-info/profile-manage-index',
    name: 'PersonalInfoLayout',
    meta: {
      hidden: true,
      title: '个人设置',
      icon: 'icon-lock'
    },
    children: [
      {
        component: () => import('@/views/profileManage/profileManageIndex.vue'),
        path: 'profile-manage-index',
        name: 'ProfileManageIndex',
        // redirect: '/profile-manage-index',
        // redirect: '/profile-manage-index/personal-info',
        meta: {
          hidden: true,
          title: '用户资料',
          icon: 'icon-document-solid',
          roles: ['admin']
        },
      },
      /* {
        component: () => import('@/views/profileManage/accountInfoComp.vue'),
        // hidden: false,
        path: 'account-info',
        meta: { title: '个人信息' },
        name: 'accountInfo'
      }, */
      {
        component: () => import('@/views/profileManage/changePwdComp.vue'),
        path: 'change-pwd',
        meta: { title: '修改密码' },
        name: 'ChangePwd'
      },
      {
        component: () => import('@/views/profileManage/aiKeyComp.vue'),
        path: 'ai-key',
        meta: { title: '绑定ChatGPT' },
        name: 'AiKey'
      }
    ]
  },
  {
    id: '4',
    parentId: '',
    // component: 'loginedLayout',
    component: () => import('@/layouts/loginedLayout.vue'),
    path: '/account-config',
    enabled: '1',
    redirect: '/account-config/force-change-pwd',
    name: 'ChangePwd',
    meta: {
      hidden: true,
      title: '修改密码页',
      icon: 'icon-lock'
    },
    children: [
      {
        id: '41',
        parentId: '4',
        // component: 'forceChangePwdComp',
        component: () => import('@/views/changePwd/forceChangePwdComp.vue'),
        enabled: '1',
        hidden: false,
        path: 'force-change-pwd',
        name: 'ForceChangePwdComp',
        meta: { title: '修改密码页(dev)' }
      },
      {
        id: '42',
        parentId: '4',
        // component: 'resetPwdComp',
        component: () => import('@/views/resetPwd/resetPwdComp.vue'),
        enabled: '1',
        hidden: false,
        path: 'reset-pwd',
        name: 'ResetPwdComp',
        meta: { title: '重置密码页(dev)' }
      }
    ]
  },
  // {
  //   component: () => import('@/views/profileManage/profileManageIndex.vue'),
  //   enabled: '1',
  //   path: '/profile-manage-index',
  //   name: 'profileManageIndex',
  //   // redirect: '/profile-manage-index',
  //   meta: {
  //     hidden: true,
  //     title: '个人设置',
  //     icon: 'icon-document-solid',
  //     roles: ['admin']
  //   },
  //   // children: [
  //   //   {
  //   //     component: () => import('@/views/profileManage/components/personalInfoComp.vue'),
  //   //     enabled: '1',
  //   //     hidden: false,
  //   //     path: 'personal-info',
  //   //     meta: { title: '个人信息' },
  //   //     name: 'PersonalInfo'
  //   //   }
  //   // ]
  // },
  /* 404 */
  {
    path: '/:all(.*)*',
    name: 'notFound',
    component: () => import('@/views/[...all].vue'),
    meta: {
      title: '找不到页面',
      hidden: true
    },
  },
]
// 针对系统展示动态路由的预备默认菜单 20240508
const constantMenuRoutes: RouteRecordRaw[] = [
  /* {
    path: '/',
    component: Layout,
    meta: {
      // title: () => useSettingsStore().settings.home.title,
      title: '首页',
      icon: 'icon-home-solid',
      breadcrumb: false,
      hidden: false
    },
    redirect: '/data-middle-office',
    children: [
      {
        path: 'data-middle-office',
        component: () => import('@/views/dashboard/index.vue'),
        name: 'DataMiddleOffice',
        meta: {
          title: '首页',
          icon: '',
          affix: true,
          hidden: false
        },
      },
    ],
  }, */
]

// 系统路由
const systemRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('@/layouts/index.vue'),
    meta: {
      title: () => useSettingsStore().settings.home.title,
      breadcrumb: false,
    },
    children: [
      // {
      //   path: '',
      //   name: 'home',
      //   component: () => import('@/views/index.vue'),
      //   meta: {
      //     title: () => useSettingsStore().settings.home.title,
      //     breadcrumb: false,
      //   },
      // },
      {
        path: 'reload',
        name: 'reload',
        component: () => import('@/views/reload.vue'),
        meta: {
          title: '重新加载',
          breadcrumb: false,
        },
      },
      {
        path: 'setting',
        name: 'personalSetting',
        component: () => import('@/views/personal/setting.vue'),
        meta: {
          title: '个人设置',
          cache: 'personalEditPassword',
        },
      },
      {
        path: 'edit/password',
        name: 'personalEditPassword',
        component: () => import('@/views/personal/edit.password.vue'),
        meta: {
          title: '修改密码',
        },
      },
    ],
  },
  // {
  //   path: '/',
  //   component: Layout,
  //   meta: {
  //     // title: () => useSettingsStore().settings.home.title,
  //     title: '首页',
  //     icon: 'icon-home-solid',
  //     breadcrumb: false,
  //     hidden: false
  //   },
  //   redirect: '/data-middle-office',
  //   children: [
  //     {
  //       path: 'data-middle-office',
  //       component: () => import('@/views/dashboard/index.vue'),
  //       name: 'DataMiddleOffice',
  //       meta: {
  //         title: '首页',
  //         icon: '',
  //         affix: true,
  //         hidden: false
  //       },
  //     },
  //   ],
  // },
]

// 前端路由：异步路由（异步路由、导航栏路由）
const asyncRoutes: Route.recordMainRaw[] = [
  // {
  //   meta: {
  //     title: '演示',
  //     icon: 'sidebar-default',
  //   },
  //   children: [
  //     // MultilevelMenuExample,
  //     // BreadcrumbExample,
  //   ],
  // },
]

const constantRoutesByFilesystem = generatedRoutes.filter((item) => {
  return item.meta?.enabled !== false && item.meta?.constant === true
})

const asyncRoutesByFilesystem = setupLayouts(generatedRoutes.filter((item) => {
  return item.meta?.enabled !== false && item.meta?.constant !== true && item.meta?.layout !== false
}))

export {
  constantRoutes,
  constantMenuRoutes,
  systemRoutes,
  asyncRoutes,
  constantRoutesByFilesystem,
  asyncRoutesByFilesystem,
}
