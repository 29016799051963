import type { Settings } from '@/types/global'

const globalSettings: Settings.all = {
  app: {
    // 如果权限功能开启，则需要对路由数据进行筛选过滤
    enablePermission: false,
    // 路由生成方式：frontend 前端, backend 后端服务, filesystem 基于文件系统（框架默认不启用该特性，如果需要开启，需要到应用配置里修改设置）。
    routeBaseOn: 'backend',
    // elementSize: 'default' // 这项配置有bug，字面意思是设置element组件默认尺寸，但是会导致el-button这样的组件在任何type都带有el-xx-default的class 20240910
    enableDynamicTitle: true
  },
  /* copyright: {
    enable: true
  }, */
  home: {
    enable: false
  },
  menu: {
    /* side-两级菜单（带一个侧边栏，常见的菜单为侧边栏的次级）, single-单纯二级菜单（不显示一级的侧边栏）20231204 */
    menuMode: 'single',
    subMenuUniqueOpened: true,
    // subMenuOnlyOneHide: true, (pro only)
  },
  topbar: {
    mode: 'fixed'
  }
}

export default globalSettings
